@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'DM Sans', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}
input.defaultCheckbox {
  color: white;
}

input.defaultCheckbox::before {
  content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66662 10.115L12.7946 3.98633L13.7379 4.92899L6.66662 12.0003L2.42395 7.75766L3.36662 6.81499L6.66662 10.115Z' fill='white'/%3E%3C/svg%3E%0A");
  fill: currentColor;
  opacity: 0;
  height: 16px;
  width: 16px;
  top: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input.defaultCheckbox::before path {
  fill: currentColor;
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}
